<template>
    <div class="wrapper block-full-content block12">
        <b-container>
            <b-card class="text-left p-2 dshadow">
                <b-card-title title-tag="h2" class="banner" style="z-index:40;">Case Study:<span class="emphasized">Newmont Corp.</span></b-card-title>
                <div class="block block-banner p-1 pt-3 pt-lg-0">
                    <b-row>
                        <b-col md="5">
                            <b-img src="img/casestudy-newmont.jpg" fluid-grow alt=""></b-img>
                            <div class="block pt-3">
                                <p class="font-weight-medium">&ldquo;With support from Save on Energy, we achieved an industry-first advanced electrification, and are on our way to eliminating diesel particulate matter from the underground environment. The project benefits will help us reduce energy costs and protect employee health while minimizing impacts to the environment.&rdquo;</p>
                                <p class="small font-weight-semibold"><strong>JOHN MULLALLY</strong><br>
                                Country Manager and Senior Director, Sustainability and External Relations<br>
                                Newmont Corp.</p>
                            </div>
                        </b-col>
                        <b-col md="7">
                            <div class="block">
                                <p class="h5 block-p font-weight-semibold">Borden's entire fleet of underground vehicles and heavy equipment will be either electric or battery-powered in place of conventional diesel-powered equipment and features state-of-the-art health and safety controls, and digital mining technologies and processes.</p>
                                <div class="block block-slides pt-3 pb-2">
                                    <b-row>
                                        <b-col lg="6">
                                            <div class="block block-slide-fade pb-1">
                                                <b-row no-gutters>
                                                    <b-col cols="3">
                                                        <b-img src="img/Icon_Canada_2c_rgb-01.svg" fluid-grow alt=""></b-img>
                                                    </b-col>
                                                    <b-col cols="9">
                                                        <div class="block pl-3">
                                                            <p class="block-p font-weight-semibold">Located near Chapleau, Ontario, Newmont’s Borden mine came into production in October 2019</p>
                                                        </div>
                                                    </b-col>
                                                </b-row>
                                            </div>
                                            <div class="block block-slide-fade pb-1">
                                                <b-row no-gutters>
                                                    <b-col cols="3">
                                                        <b-img src="img/Icon_Benefits-Savings_2c_rgb-01.svg" fluid-grow alt=""></b-img>
                                                    </b-col>
                                                    <b-col cols="9">
                                                        <div class="block pl-3">
                                                            <p class="block-p font-weight-semibold">Newmont received support from the IESO’s Save on Energy programs</p>
                                                        </div>
                                                    </b-col>
                                                </b-row>
                                            </div>
                                        </b-col>
                                        <b-col lg="6">
                                            <div class="block block-slide-fade pb-1">
                                                <b-row no-gutters>
                                                    <b-col cols="3">
                                                        <b-img src="img/Icon_Brochure_2c_rgb-01.svg" fluid-grow alt=""></b-img>
                                                    </b-col>
                                                    <b-col cols="9">
                                                        <div class="block pl-3">
                                                            <p class="block-p font-weight-semibold"><span class="h2 d-block bold mb-0">9 GWh</span>Expected annual energy savings resulting from an innovative “Ventilation On Demand” project that limits ventilation to when and where it is required</p>
                                                        </div>
                                                    </b-col>
                                                </b-row>
                                            </div>
                                        </b-col>
                                    </b-row>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                    <div class="block text-right pt-3">
                        <b-button v-on:click="gotoNext" variant="primary" class="mb-1 p-hide">Next&nbsp;&#9662;</b-button>
                    </div>
                </div>
            </b-card>
        </b-container>
    </div>
</template>
<script>
import BlockMixin from './BlockMixin.vue';
export default {
    title:'Case Study: Newmont Corp.',
    id: 'case-study-newmont-corp',
    name: 'Block12',
    mixins: [ BlockMixin ],
    methods: {
        animateItem(){
            this.$el.classList.add( 'active' );
            this.show = true;
            this.blockSlideFade();
        }
    }
}
</script>