<template>
    <div class="wrapper block4">
        <b-container>
            <b-row>
                <b-col lg="5" class="order-1 order-lg-0">
                    <b-card class="text-left p-2 font-weight-bold dshadow" style="z-index:40;">
                        <h2 class="h5 bold banner">2019-2020 Save on Energy Programs</h2>
                        <div class="block pt-2">
                            <ul>
                                <li class="checkmark">Retrofit</li>
                                <li class="checkmark">Small Business Lighting</li>
                                <li class="checkmark">Energy Manager</li>
                                <li class="checkmark">Process and Systems Upgrade Program</li>
                                <li class="checkmark">Energy Performance Program</li>
                                <li class="checkmark">Home Assistance Program</li>
                                <li class="checkmark">First Nations Programs</li>
                                <li class="checkmark">Local Program Fund</li>
                            </ul>
                        </div>
                        <div class="block text-right pt-3">
                            <b-button v-on:click="gotoNext" variant="primary" class="mb-1 p-hide">Next&nbsp;&#9662;</b-button>
                        </div>
                    </b-card>
                </b-col>
                <b-col lg="7" class="pl-lg-0">
                    <transition name="slide-right">
                        <div v-show="show" class="p-show block nl-mrgn text-right mt-lg-5">
                            <b-img src="img/slide5.jpg" fluid-grow alt="" class="mb-lg-4"></b-img>
                        </div>
                    </transition>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
<script>
import BlockMixin from './BlockMixin.vue';
export default {
    title: '2019-2020 Save on Energy Programs',
    id: 'soe-programs',
    name: 'Block4',
    mixins: [ BlockMixin ],
    methods: {
        animateItem(){
            this.$el.classList.add( 'active' );
            this.show = true;
            this.blockSlideFade();
        }
    }
}
</script>
<style lang="scss">

</style>