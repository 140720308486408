<template>
    <div class="wrapper block20">
        <b-container>
            <div class="block block-slides">
                <div class="block">
                    <h2 class="bold white pb-4">The future of energy efficiency is rapidly evolving to:</h2>
                </div>
                <b-row>
                    <b-col md="6">
                        <div class="block block-item dshadow block-slide-fade">
                            <p class="block-p">enable customer-focused solutions</p>
                        </div>
                        <div class="block block-item dshadow block-slide-fade">
                            <p class="block-p">competitively procure energy efficiency to ensure cost-effectiveness</p>
                        </div>
                        <div class="block block-item dshadow block-slide-fade">
                            <p class="block-p">respond to changing needs, particularly in vulnerable communities</p>
                        </div>
                    </b-col>
                    <b-col md="6">
                        <div class="block block-item dshadow block-slide-fade">
                            <p class="block-p">contribute to Ontario’s postpandemic economic recovery, particularly for small businesses</p>
                        </div>
                        <div class="block block-item dshadow block-slide-fade">
                            <p class="block-p">deliver even greater electricity system resiliency and ratepayer value</p>
                        </div>
                    </b-col>
                </b-row>
                <div class="block text-center pt-5">
                    <b-button v-on:click="gotoNext" variant="primary" class="btn-lrg mb-1 p-hide">Next&nbsp;&#9662;</b-button>
                </div>
            </div>
        </b-container>
    </div>
</template>
<script>
import BlockMixin from './BlockMixin.vue';
export default {
    title:'The future of energy efficiency',
    id: 'the-future-of-energy-efficiency',
    name: 'Block20',
    mixins: [ BlockMixin ],
    methods: {
        animateItem(){
            this.$el.classList.add( 'active' );
            this.show = true;
            this.blockSlideFade();
        }
    }
}
</script>