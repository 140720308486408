<template>
    <div class="wrapper block3">
        <b-container>
            <b-row>
                <b-col lg="5">
                    <b-card class="text-left p-2 font-weight-bold dshadow" style="z-index:40;">
                        <h2 class="h5 bold banner">2019-2020 Save on Energy highlights</h2>
                        <div class="block block-slides">
                            <div class="block block-slide-fade pt-3 pb-1">
                                <b-row no-gutters>
                                    <b-col cols="3">
                                        <b-img src="img/Icon_Benefits-Savings_2c_rgb-01.svg" fluid-grow alt=""></b-img>
                                    </b-col>
                                    <b-col cols="9">
                                        <div class="block pl-3">
                                            <p class="block-p"><span class="h1 d-block bold mb-0">$353&nbsp;M</span>Invested in Energy Efficiency projects</p>
                                        </div>
                                    </b-col>
                                </b-row>
                            </div>
                            <div class="block block-slide-fade pb-1">
                                <b-row no-gutters>
                                    <b-col cols="3">
                                        <b-img src="img/Icon_Results-Time-Up_2c_rgb-01.svg" fluid-grow alt=""></b-img>
                                    </b-col>
                                    <b-col cols="9">
                                        <div class="block pl-3">
                                            <p class="block-p"><span class="h1 d-block bold mb-0">1.5&nbsp;TWh</span>Total Electricity Savings</p>
                                        </div>
                                    </b-col>
                                </b-row>
                            </div>
                            <div class="block block-slide-fade pb-3">
                                <b-row no-gutters>
                                    <b-col cols="3">
                                        <b-img src="img/Icon_Graph-Up_2c_rgb-01.svg" fluid-grow alt=""></b-img>
                                    </b-col>
                                    <b-col cols="9">
                                        <div class="block pl-3">
                                            <p class="block-p"><span class="h1 d-block bold mb-0">186.4&nbsp;MW</span>Total Demand Savings</p>
                                        </div>
                                    </b-col>
                                </b-row>
                            </div>
                        </div>
                        <div class="block text-right pt-3">
                            <b-button v-on:click="gotoNext" variant="primary" class="mb-1 p-hide">Next&nbsp;&#9662;</b-button>
                        </div>
                    </b-card>
                </b-col>
                <b-col lg="7" class="pl-lg-0">
                    <transition name="slide-right">
                        <div v-show="show" class="p-show block">
                            <div class="block text-left mt-lg-4 p-2">
                                <p class="h5 pb-2 bold white">The electricity saved is equivalent to powering a city the size of Oshawa for one year</p>
                            </div>
                            <div class="block text-right nl-mrgn">
                                <b-img src="img/slide4.jpg" fluid-grow alt="" class="mb-3"></b-img>
                            </div>
                        </div>
                    </transition>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
<script>
import BlockMixin from './BlockMixin.vue';
export default {
    title: '2019-2020 Save on Energy highlights',
    id: 'soe-highlights',
    name: 'Block3',
    mixins: [ BlockMixin ],
    methods: {
        animateItem(){
            this.$el.classList.add( 'active' );
            this.show = true;
            this.blockSlideFade();
        }
    }
}
</script>