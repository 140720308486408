<template>
    <div class="wrapper block18">
        <b-container>
            <b-row>
                <b-col lg="6" class="pl-lg-0 pt-5">
                    <transition name="slide-right">
                        <div v-show="show" class="p-show block text-left">
                            <div class="block">
                                <b-img src="img/slide15.jpg" fluid-grow alt="" class="mt-3"></b-img>
                            </div>
                        </div>
                    </transition>
                </b-col>
                <b-col lg="6">
                    <b-card class="text-left p-2 font-weight-bold dshadow banner-card-left" style="z-index:40;">
                        <b-card-title title-tag="h2" class="banner">Support for greenhouse owners</b-card-title>
                        <div class="banner-left">
                            <div class="block block-slides">
                                <div class="block block-slide-fade">
                                    <p>Energy efficiency is part of a broader solution to address increasing energy needs in southwestern Ontario.</p>
                                </div>
                                <div class="block block-slide-fade">
                                    <p>The adoption of energy-efficient processes and technologies by businesses and communities has helped to reduce peak demand in the region by 10 MW.*</p>
                                </div>
                            </div>
                            <p class="small font-weight-medium">* Includes savings from 2015 Save on Energy programs and projects implemented with support from the IESO Grid Innovation Fund.</p>
                        </div>
                        <div class="block text-right pt-3">
                            <b-button v-on:click="gotoNext" variant="primary" class="mb-1 p-hide">Next&nbsp;&#9662;</b-button>
                        </div>
                    </b-card>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
<script>
import BlockMixin from './BlockMixin.vue';
export default {
    title: 'Support for greenhouse owners',
    id: 'support-for-greenhouse-owners',
    name: 'Block18',
    mixins: [ BlockMixin ],
    methods: {
        animateItem(){
            this.$el.classList.add( 'active' );
            this.show = true;
            this.blockSlideFade();
        }
    }
}
</script>