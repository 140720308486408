<script>
export default {
    id: '',
    props: {
        gotoItem: { type: Function },
    },
    data() {
        return {
            show: false,
            complete: false,
            count: 0,
            timer: 399
        };
    },
    methods: {
        gotoNext(){
            this.gotoItem();
        },
        blockSlideFade(){
            const $blocks = this.$el.querySelectorAll( '.block-slide-fade' );
            if( this.count >= $blocks.length ){
                return;
            }
            var $this  = this;
            var $block = $blocks[ this.count ];
            setTimeout( () => {
                $block.classList.add( 'active' );
                $this.count++;
                $this.blockSlideFade();
            }, $this.timer );
        },
        animateItem(){
            this.$el.classList.add( 'active' );
        },
        showItem(){
            if( !this.complete ){
                this.$el.classList.add( 'complete' );
                this.$el.classList.add( 'active' );
                this.show = true;
                this.complete = true;
            }
        },
    }
}
</script>