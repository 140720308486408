<template>
    <div class="wrapper block-full-content block21">
        <b-container>
            <b-card class="text-left p-2 dshadow">
                <b-card-title title-tag="h2" class="banner" style="z-index:40;"><span class="emphasized">Program Progress</span></b-card-title>
                <div class="block banner-left pt-2">
                    <p class="font-weight-semibold">Save on Energy programs contributed to 1.5 TWh of electricity savings and 186.4 MW of demand reduction from 2019 to 2020. The results demonstrate the value that energy efficiency provides for the electricity system and customers, and helps inform the future of conservation and demand management programs in the province. The data below includes customer participation and net-unverified contracted costs and savings. Totals include both committed and in-service project savings.</p>
                    <p class="font-weight-semibold">Final reported results will be provided later this fall with the posting of the Evaluation, Measurement and Verification (EM&amp;V) reports. The EM&amp;V process assesses the resource savings, cost-effectiveness and market impacts of each program. The reports will be available on the IESO website.</p>
                    <div class="block pt-3">
                        <div class="table-responsive">
                            <table class="table table-bordered block-table">
                                <thead>
                                    <tr>
                                        <th>Program</th>
                                        <th>Electricity Savings (GWh)</th>
                                        <th>Demand Savings (MW)</th>
                                        <th>Project Costs ($M)</th>
                                        <th>Incentive Costs ($M)</th>
                                        <th>Participation</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Retrofit Program</td>
                                        <td>1,187.9</td>
                                        <td>144.7</td>
                                        <td>47.7</td>
                                        <td>145.3</td>
                                        <td>11,128 projects</td>
                                    </tr>
                                    <tr>
                                        <td>Small Business Lighting Program</td>
                                        <td>47.2</td>
                                        <td>11.6</td>
                                        <td>3.7</td>
                                        <td>11.2</td>
                                        <td>6,826 projects</td>
                                    </tr>
                                    <tr>
                                        <td>Energy Manager Program</td>
                                        <td>46.6</td>
                                        <td>5.0</td>
                                        <td>1.3</td>
                                        <td>14.0</td>
                                        <td>195+ energy managers</td>
                                    </tr>
                                    <tr>
                                        <td>Process and Systems Upgrade Program</td>
                                        <td>196.5</td>
                                        <td>20.2</td>
                                        <td>4.7</td>
                                        <td>46.0</td>
                                        <td>106 projects</td>
                                    </tr>
                                    <tr>
                                        <td>Energy Performance Program</td>
                                        <td>9.9</td>
                                        <td>1.2</td>
                                        <td>0.4</td>
                                        <td>1.1</td>
                                        <td>22 facilities</td>
                                    </tr>
                                    <tr>
                                        <td>Home Assistance Program</td>
                                        <td>19.6</td>
                                        <td>1.9</td>
                                        <td>4.8</td>
                                        <td>30.2</td>
                                        <td>22,859 homes</td>
                                    </tr>
                                    <tr>
                                        <td>Indigenous Programs</td>
                                        <td>0.5</td>
                                        <td>0.0</td>
                                        <td>n/a</td>
                                        <td>7.1</td>
                                        <td>823 homes</td>
                                    </tr>
                                    <tr>
                                        <td>Local Program Fund</td>
                                        <td>8.7</td>
                                        <td>1.8</td>
                                        <td>n/a</td>
                                        <td>18.0</td>
                                        <td>44,779 projects*</td>
                                    </tr>
                                    <tr>
                                        <td>IESO Central Services</td>
                                        <td>n/a</td>
                                        <td>n/a</td>
                                        <td>18.0</td>
                                        <td>n/a</td>
                                        <td>&nbsp;</td>
                                    </tr>
                                    <tr class="bold">
                                        <td>Total</td>
                                        <td>1,516.9</td>
                                        <td>186.4</td>
                                        <td>80.6</td>
                                        <td>272.9</td>
                                        <td>&nbsp;</td>
                                    </tr>
                                    <tr class="bold">
                                        <td>Interim Framework CDM Plan Target</td>
                                        <td>1,429.4</td>
                                        <td>189.5</td>
                                        <td>80.5</td>
                                        <td>272.9</td>
                                        <td>&nbsp;</td>
                                    </tr>
                                    <tr class="bold tint">
                                        <td>Actual vs. Target</td>
                                        <td>106%</td>
                                        <td>98%</td>
                                        <td>100%</td>
                                        <td>100%</td>
                                        <td>&nbsp;</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <p class="small font-weight-medium pt-2">*756 projects from Refrigeration Efficiency Program, 42,621 homes from Community Conservation Program and 1,402 projects from Swimming Pool Efficiency Program<br>
                        Changes to building codes and product standards help contribute to the province’s conservation savings. These savings are covered in the IESO’s <a href="https://www.ieso.ca/-/media/Files/IESO/Document-Library/planning-forecasts/apo/Annual-Planning-Outlook-Dec2020.ashx" target="_blank"><u>Annual Planning Outlook</u></a>.</p>
                    </div>
                    <div class="block text-right pt-3">
                        <b-button v-on:click="gotoNext" variant="primary" class="mb-1 p-hide">Next&nbsp;&#9662;</b-button>
                    </div>
                </div>
            </b-card>
        </b-container>
    </div>
</template>
<script>
import BlockMixin from './BlockMixin.vue';
export default {
    title:'Program Progress',
    id: 'program-progress',
    name: 'Block21',
    mixins: [ BlockMixin ]
}
</script>