<template>
    <div class="wrapper block9">
        <b-container>
            <b-row>
                <b-col lg="5" class="order-1 order-lg-0">
                    <b-card class="text-left p-2 font-weight-bold dshadow" style="z-index:40;">
                        <b-card-title title-tag="h2" class="banner">Energy Manager program</b-card-title>
                        <p class="pt-3 banner-left">The Energy Manager program is the largest of its kind in North America, with trained energy-efficiency professionals helping large organizations save energy and drive Ontario’s competitiveness</p>
                        <b-card-text class="h5 pt-3 pb-3 banner-left dark-teal"><strong>2019-2020 RESULTS:</strong></b-card-text>
                        <div class="block block-slides">
                            <div class="block block-slide-fade pb-2">
                                <b-row no-gutters>
                                    <b-col cols="3">
                                        <b-img src="img/Icon_Participants_2c_rgb-01.svg" fluid-grow alt=""></b-img>
                                    </b-col>
                                    <b-col cols="9">
                                        <div class="block pl-3">
                                            <p class="block-p"><span class="h1 d-block bold mb-0">195</span>Employed</p>
                                        </div>
                                    </b-col>
                                </b-row>
                            </div>
                            <div class="block block-slide-fade pb-1">
                                <b-row no-gutters>
                                    <b-col cols="3">
                                        <b-img src="img/Icon_Results-Time-Up_2c_rgb-01.svg" fluid-grow alt=""></b-img>
                                    </b-col>
                                    <b-col cols="9">
                                        <div class="block pl-3">
                                            <p class="block-p"><span class="h1 d-block bold mb-0">46.6 GWh</span>Total Electricity Savings</p>
                                        </div>
                                    </b-col>
                                </b-row>
                            </div>
                            <div class="block block-slide-fade pb-3">
                                <b-row no-gutters>
                                    <b-col cols="3">
                                        <b-img src="img/Icon_Graph-Up_2c_rgb-01.svg" fluid-grow alt=""></b-img>
                                    </b-col>
                                    <b-col cols="9">
                                        <div class="block pl-3">
                                            <p class="block-p"><span class="h1 d-block bold mb-0">5.0 MW</span>Total Demand Savings</p>
                                        </div>
                                    </b-col>
                                </b-row>
                            </div>
                        </div>
                        <div class="block text-right pt-3">
                            <b-button v-on:click="gotoNext" variant="primary" class="mb-1 p-hide">Next&nbsp;&#9662;</b-button>
                        </div>
                    </b-card>
                </b-col>
                <b-col lg="7" class="pl-lg-0">
                    <transition name="slide-right">
                        <div v-show="show" class="p-show block text-left nl-mrgn mt-lg-5">
                            <b-img src="img/slide8.jpg" fluid-grow alt="" class="mt-3"></b-img>
                        </div>
                    </transition>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
<script>
import BlockMixin from './BlockMixin.vue';
export default {
    title: 'Energy Manager program',
    id: 'energy-manager-program',
    name: 'Block9',
    mixins: [ BlockMixin ],
    methods: {
        animateItem(){
            this.$el.classList.add( 'active' );
            this.show = true;
            this.blockSlideFade();
        }
    }
}
</script>