<template>
    <div class="wrapper block1">
        <b-container>
            <div class="block">
                <b-row>
                    <b-col lg="5" class="order-1 order-lg-0">
                        <transition name="slide-fade">
                            <b-card v-show="show" class="p-show text-left p-2 mt-lg-5 dshadow" style="z-index:40;">
                                <b-card-text class="h4 pb-3">
                                    <strong>Save on Energy celebrates 10 years in 2021. More than 250,000 Ontario residents and businesses have relied on Save on Energy programs to better manage their energy use.</strong>
                                </b-card-text>
                                <b-img src="img/SOE-10years.png" fluid alt="10 Years of SaveOnEnergy" class="pt-3 pb-4"></b-img>
                                <div class="block text-right pt-3">
                                    <b-button v-on:click="gotoNext" variant="primary" class="mb-1 p-hide">Next&nbsp;&#9662;</b-button>
                                </div>
                            </b-card>
                        </transition>
                    </b-col>
                    <b-col lg="7" class="pl-lg-0">
                        <transition name="fade">
                            <div v-show="show" class="p-show block nl-mrgn">
                                <b-img src="img/slide2.jpg" fluid-grow alt="" class="mb-lg-4"></b-img>
                            </div>
                        </transition>
                    </b-col>
                </b-row>
            </div>
        </b-container>
    </div>
</template>
<script>
import BlockMixin from './BlockMixin.vue';
export default {
    title: 'Save on Energy celebrates 10 years in 2021',
    id:'soe-celebrates-10-years-in-2021',
    name: 'Block1',
    mixins: [ BlockMixin ],
    methods: {
        animateItem(){
            this.$el.classList.add( 'active' );
            this.show = true;
        }
    }
}
</script>