<template>
    <div class="wrapper block19">
        <b-container>
            <b-row>
                <b-col lg="5" class="order-1 order-lg-0">
                    <b-card class="text-left p-2 font-weight-bold dshadow" style="z-index:40;">
                        <b-card-title title-tag="h2" class="banner">The current framework for energy efficiency</b-card-title>
                        <div class="banner-left">
                            <p class="pt-3">The IESO’s 2021-2024 Conservation and Demand Management Framework will invest almost $700 million to reduce peak demand by 440 MW and consumption by 2.7 TWh.</p>
                            <p>2021-2024 CDM programs focus on those who need them most, including businesses, First Nation communities and Income-eligible residents.</p>
                        </div>
                        <div class="block text-right pt-3">
                            <b-button v-on:click="gotoNext" variant="primary" class="mb-1 p-hide">Next&nbsp;&#9662;</b-button>
                        </div>
                    </b-card>
                </b-col>
                <b-col lg="7" class="pl-lg-0">
                    <transition name="slide-right">
                        <div v-show="show" class="p-show block text-left nl-mrgn mt-lg-5">
                            <b-img src="img/slide16.jpg" fluid-grow alt="" class="mt-lg-3"></b-img>
                        </div>
                    </transition>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
<script>
import BlockMixin from './BlockMixin.vue';
export default {
    title: 'The current framework for energy efficiency',
    id: 'framework-for-energy-efficiency',
    name: 'Block19',
    mixins: [ BlockMixin ],
    methods: {
        animateItem(){
            this.$el.classList.add( 'active' );
            this.show = true;
            this.blockSlideFade();
        }
    }
}
</script>