<template>
    <div class="wrapper block22">
        <b-container>
            <b-row no-gutters>
                <b-col lg="6">
                    <b-card class="text-left p-2 font-weight-bold dshadow" style="z-index:40;">
                        <b-card-title title-tag="h2" class="banner">Support for customers</b-card-title>
                        <div class="block banner-left pt-2">
                            <p class="font-weight-semibold">In response to the COVID-19 pandemic, in-person interactions between Save on Energy representatives and customers were temporarily suspended on March 16, 2020, including visits to home and businesses. Participants of the Home Assistance Program, Small Business Lighting, and First Nations Conservation Program were placed on wait lists and were contacted for appointments once it was safe to visit homes and businesses. Participation targets set in the CDM Plan by program were impacted due to the ongoing pandemic, but overall, the IF energy targets were achieved. New programs under the 2021-2024 CDM Framework will continue to provide opportunities to help those who need them most and can contribute to reduced energy costs as businesses and residents recover from the impacts of COVID-19.</p>
                            <p class="font-weight-semibold">Learn more at <a href="https://saveonenergy.ca/en/FAQs" class="font-weight-semibold" target="_blank"><u>saveonenergy.ca/en/FAQs</u></p>
                            <div class="block pt-3">
                                <b-button v-on:click="gotoNext" variant="primary" class="btn-lrg mb-1 p-hide">Next&nbsp;&#9662;</b-button>
                            </div>
                        </div>
                    </b-card>
                </b-col>
                <b-col lg="6">
                    <div class="block block-images text-center pt-lg-5">
                        <transition name="slide-right">
                            <div v-show="show" class="p-show block-image text-left">
                                <b-img src="img/support-for-customers-3.jpg" fluid alt=""></b-img>
                            </div>
                        </transition>
                        <transition name="slide-fade">
                            <div v-show="count >= 1" class="p-show block-image text-right">
                                <b-img src="img/support-for-customers-2.jpg" alt="" style=""></b-img>
                            </div>
                        </transition>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
<script>
import BlockMixin from './BlockMixin.vue';
export default {
    title:'Support for customers',
    id: 'support-for-customers',
    name: 'Block22',
    mixins: [ BlockMixin ],
    methods: {
        animateItem(){
            this.$el.classList.add( 'active' );
            this.show = true;
            this.animateItemNext();
        },
        animateItemNext(){
            if( this.count >= 2 ){
                return;
            }
            var $this = this;
            setTimeout( () => {
                $this.count++;
                $this.animateItemNext();
            }, $this.timer );
        }
    }
}
</script>