<template>
  <div id="app">
    <!-- nav ( v-b-scrollspy="{ element: '#id', offset: 50, method: 'auto' }" )-->
    <b-navbar toggleable="lg" variant="light" fixed="top" slot="header">
      <b-navbar-brand href="./" class="logo"><img alt="IESO | SOE" src="./assets/logo.png" /></b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-nav-item-dropdown text="Jump to section..." v-b-scrollspy="200" right>
            <b-dropdown-item
              v-for="( item, index ) in slideItems"
              v-bind:key="index"
              v-bind:href="`#${ item.id }`"
              @click="scrollIntoView"
            >
              {{ item.title }}
            </b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item href="./IESO_EEAR21.pdf" class="pdf" target="_blank">Download PDF</b-nav-item>
        </b-navbar-nav>
      </b-collapse>
      <b-progress :max="slideItems.length" tabindex="-1" aria-hidden="true" id="progress-bar">
        <b-progress-bar :value="slide + 1" :label-html="`<span>${ slide + 1 }/${ slideItems.length }</span>`"></b-progress-bar>
      </b-progress>
    </b-navbar>
    <!-- / nav -->
    <!-- content -->
    <div id="content"
      v-for="( item, index ) in slideItems"
      v-bind:key="index"
    >
      <component
        v-bind:is="item.name"
        v-bind:id="item.id"
        v-bind:ref="item.id"
        v-bind:gotoItem="next"
      ></component>
    </div>
    <!-- / content -->
  </div>
</template>

<script>

import Block0 from './components/blocks/Block0.vue';
import Block1 from './components/blocks/Block1.vue';
import Block2 from './components/blocks/Block2.vue';
import Block3 from './components/blocks/Block3.vue';
import Block4 from './components/blocks/Block4.vue';
import Block5 from './components/blocks/Block5.vue';
import Block6 from './components/blocks/Block6.vue';
import Block7 from './components/blocks/Block7.vue';
import Block8 from './components/blocks/Block8.vue';
import Block9 from './components/blocks/Block9.vue';
import Block10 from './components/blocks/Block10.vue';
import Block11 from './components/blocks/Block11.vue';
import Block12 from './components/blocks/Block12.vue';
import Block13 from './components/blocks/Block13.vue';
import Block14 from './components/blocks/Block14.vue';
import Block15 from './components/blocks/Block15.vue';
import Block16 from './components/blocks/Block16.vue';
import Block17 from './components/blocks/Block17.vue';
import Block18 from './components/blocks/Block18.vue';
import Block19 from './components/blocks/Block19.vue';
import Block20 from './components/blocks/Block20.vue';
import Block21 from './components/blocks/Block21.vue';
import Block22 from './components/blocks/Block22.vue';
import Block23 from './components/blocks/Block23.vue';

export default {
  name: "App",
  components: { Block0, Block1, Block2, Block3, Block4, Block5, Block6, Block7, Block8, Block9, Block10, Block11, Block12, Block13, Block14, Block15, Block16, Block17, Block18, Block19, Block20, Block21, Block22, Block23 },
  created() {
    this.$root.$on( 'bv::scrollspy::activate', this.onActivate );
  },
  data() {
    return {
      slide: 0,
      currentSlide: 0,
      offset: 200,
      pageHeight: 0,
      scrollTimeout: 0,
      scrollTimeoutCount: 3,
      slideItems: [ Block0, Block1, Block2, Block3, Block4, Block5, Block6, Block7, Block8, Block9, Block10, Block11, Block12, Block13, Block14, Block15, Block16, Block17, Block18, Block19, Block20, Block21, Block22, Block23 ],
      isIE: !!document.documentMode,
    };
  },
  methods: {
    onActivate( target ) {
      // set page height
      this.pageHeight = this.getPageHeight();
      // check for ie
      if( this.isIE ){
        const $pbar  = document.getElementById( "progress-bar" );
        const $pbody = document.getElementById( "block-body" );
        $pbar.classList.add( 'ie' );
        $pbody.style.backgroundImage = "url( 'img/background-gradient-01.jpg' )";
      }
      const href = target
      const el   = href ? document.querySelector( href ) : null
      // first slide
      if( !this.slide ){
        const firstSlide = this.$refs[ el.id ][0];
        if( !firstSlide.show ){
          firstSlide.animateItem();
        }
      }
      // update scroll index
      this.updateScollIndex( el.id );
    },
    updateScollIndex( id ){
      for( var q = 0; q < this.slideItems.length; q++ ){
        if( this.slideItems[q].id === id ){
          this.slide = q;
          if( this.$refs[id][0].animateItem && {}.toString.call( this.$refs[id][0].animateItem ) === '[object Function]' ){
            this.$refs[id][0].animateItem();
          }
        }
      }
    },
    updateScrollPos( el ){
      if( !this.isIE ){
        window.scrollTo({ top: el.offsetTop, left: 0, behavior: 'smooth' });
      }else{
        document.documentElement.scrollTop = el.offsetTop;
      }
    },
    checkForScrollUpdate( el ){
      const $this = this;
      if( this.scrollTimeout < this.scrollTimeoutCount ){
        ++this.scrollTimeout;
        setTimeout( () => {
          $this.updateScroll( el );
        }, 100 );
      }
    },
    updateScroll( el ){
      this.updateScrollPos( el );
      this.checkForScrollUpdate( el );
    },
    getPageHeight(){
      const body = document.body,
            html = document.documentElement;
      return Math.max( body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight );
    },
    scrollIntoView( event ) {
      event.preventDefault()
      const href = event.target.getAttribute( 'href' )
      const el   = href ? document.querySelector( href ) : null
      if( el ) {
        /// get prior elements before animating
        for( var q = 0; q < this.slideItems.length; q++ ){
          if( this.slideItems[q].id === el.id ){
            break;
          } else {
            this.$refs[ this.slideItems[q].id ][0].showItem();
          }
        }
        this.updateScroll( el );
      }
    },
    next(){
      if( this.slide < ( this.slideItems.length - 1 ) ){
        this.slide++;
      } else {
        this.slide = 0;
      }
      const el = document.getElementById( this.slideItems[ this.slide ].id );
      this.scrollTimeout = this.scrollTimeoutCount; /// kill scroll counter if counting
      this.updateScrollPos( el );
    },
  },
};
</script>
