<template>
    <div class="wrapper block21">
        <b-container>
            <b-row no-gutters>
                <b-col lg="5" class="pl-lg-0 mt-3">
                    <transition name="slide-right">
                        <div v-show="show" class="p-show block text-left">
                            <b-img src="img/slide18.jpg" fluid-grow alt="" class="mt-lg-3"></b-img>
                        </div>
                    </transition>
                </b-col>
                <b-col lg="7">
                    <b-card class="text-left p-2 font-weight-bold dshadow" style="z-index:40;">
                        <b-row>
                            <b-col lg="7">
                                <b-card-title title-tag="h3">Learn more today:</b-card-title>
                                <div class="block pt-2">
                                    <p><a href="https://saveonenergy.ca/en/Overview/CDM-Framework-Program-Details" target="_blank">Current Save on Energy programs</a></p>
                                    <p><a href="https://saveonenergy.ca/For-Your-Home/First-Nations-Conservation-Program" target="_blank">First Nations Conservation Program</a></p>
                                    <p><a href="https://www.ieso.ca/en/learn/ontario-supply-mix/ontario-energy-capacity" target="_blank">Ontario’s energy needs</a></p>
                                    <p><a href="https://www.ieso.ca/en/Sector-Participants/Market-Operations/Markets-and-Related-Programs/Energy-Efficiency-Auction-Pilot" target="_blank">Energy Efficiency Auction</a></p>
                                    <p><a href="https://www.ieso.ca/en/Get-Involved/Funding-Programs/Grid-Innovation-Fund/Overview" target="_blank">Grid Innovation Fund</a></p>
                                    <b-img src="img/logo.png" fluid-grow alt="" class="mt-3"></b-img>
                                </div>
                            </b-col>
                            <b-col lg="5">
                                <div class="block">
                                    <p><strong>Independent Electricity System Operator</strong></p>
                                    <p class="pb-1 small font-weight-semibold">Phone: 905.403.6900<br>
                                    Toll-free: 1.888.448.7777<br>
                                    Email: <a href="mailto:customer.relations@ieso.ca">customer.relations@ieso.ca</a></p>
                                    <p class="small font-weight-semibold"><a href="https://twitter.com/IESO_Tweets" target="_blank" class="twitter">@IESO_Tweets</a><br>
                                    <a href="https://www.linkedin.com/company/ieso/" target="_blank" class="linkedin">linkedin.com/company/ieso</a></p>
                                    <p class="pb-2"><a href="https://www.ieso.ca/" target="_blank">ieso.ca</a></p>
                                    <p><strong>Save on Energy</strong></p>
                                    <p class="small font-weight-semibold"><a href="https://twitter.com/saveONenergyOnt" target="_blank" class="twitter">@SaveonEnergyONT</a><br>
                                    <a href="https://www.facebook.com/SaveonEnergyOntario/?ref=aymt_homepage_panel" target="_blank" class="facebook">SaveonEnergyOntario</a><br>
                                    <a href="https://linkedin.com/company/saveonenergy-ontario" target="_blank" class="linkedin">linkedin.com/company/<br>saveonenergy-ontario</a><br></p>
                                    <p class="pb-4"><a href="https://saveonenergy.ca/" target="_blank">SaveOnEnergy.ca</a></p>
                                </div>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
            </b-row>
            <div class="block text-center pt-5">
                <b-button v-on:click="gotoNext" variant="primary" class="start p-hide">Back to top&nbsp;&#9652;</b-button>
            </div>
        </b-container>
    </div>
</template>
<script>
import BlockMixin from './BlockMixin.vue';
export default {
    title: 'Learn more today',
    id: 'learn-more-today',
    name: 'Block23',
    mixins: [ BlockMixin ],
    methods: {
        animateItem(){
            this.$el.classList.add( 'active' );
            this.show = true;
            this.blockSlideFade();
        }
    }
}
</script>