<template>
    <div class="wrapper block16">
        <b-container>
            <b-row>
                <b-col lg="5" class="order-1 order-lg-0">
                    <b-card class="text-left p-2 font-weight-bold dshadow" style="z-index:40;">
                        <b-card-title title-tag="h2" class="banner">Local Program Fund</b-card-title>
                        <p class="pt-3 banner-left">In addition to the suite of Save on Energy programs, communities are benefiting from tailored programming funded through the Local Program Fund and designed and delivered by local distribution companies. These programs help meet the needs of local areas and include incentives to businesses and residents for programs involving refrigeration, smart thermostats, block heater timers and more.</p>
                        <div class="block block-slides ">
                            <div class="block block-slide-fade pt-3 pb-1">
                                <b-row no-gutters>
                                    <b-col cols="3">
                                        <b-img src="img/Icon_Benefits-Savings_2c_rgb-01.svg" fluid-grow alt=""></b-img>
                                    </b-col>
                                    <b-col cols="9">
                                        <div class="block pl-3">
                                            <p class="block-p"><span class="h1 d-block bold mb-0">10</span>Local Programs Approved (2019-2020)</p>
                                        </div>
                                    </b-col>
                                </b-row>
                            </div>
                        </div>
                        <div class="block text-right pt-3">
                            <b-button v-on:click="gotoNext" variant="primary" class="mb-1 p-hide">Next&nbsp;&#9662;</b-button>
                        </div>
                    </b-card>
                </b-col>
                <b-col lg="7" class="pl-lg-0">
                    <transition name="slide-right">
                        <div v-show="show" class="p-show block text-left nl-mrgn mt-lg-5">
                            <b-img src="img/slide13.jpg" fluid-grow alt="" class="mt-3"></b-img>
                        </div>
                    </transition>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
<script>
import BlockMixin from './BlockMixin.vue';
export default {
    title: 'Local Program Fund',
    id: 'local-program-fund',
    name: 'Block16',
    mixins: [ BlockMixin ],
    methods: {
        animateItem(){
            this.$el.classList.add( 'active' );
            this.show = true;
            this.blockSlideFade();
        }
    }
}
</script>