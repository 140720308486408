<template>
    <div class="wrapper block2">
        <b-container>
            <b-row no-gutters>
                <b-col lg="6">
                    <div class="block block-images text-center pl-5">
                        <transition name="slide-right">
                            <div v-show="show" class="p-show block-image text-left">
                                <b-img src="img/slide3a.jpg" fluid alt=""></b-img>
                            </div>
                        </transition>
                        <transition name="slide-fade">
                            <div v-show="count >= 1" class="p-show block-image text-right">
                                <b-img src="img/slide3b.jpg" fluid alt=""></b-img>
                            </div>
                        </transition>
                        <transition name="slide-right">
                            <div v-show="count >= 2" class="p-show block-image text-left">
                                <b-img src="img/slide3c.jpg" fluid alt=""></b-img>
                            </div>
                        </transition>
                        <transition name="slide-fade">
                            <div v-show="count >= 3" class="p-show block-image text-right">
                                <b-img src="img/slide3d.jpg" fluid alt=""></b-img>
                            </div>
                        </transition>
                    </div>
                </b-col>
                <b-col lg="5">
                    <transition name="slide-fade">
                        <b-card v-show="show" class="p-show text-left font-weight-bold dshadow p-2">
                            <h2 class="h5 pb-2 bold">Everyone benefits from energy efficiency</h2>
                            <p>It helps:</p>
                            <div class="block">
                                <ul>
                                    <li class="checkmark">enable consumers to better manage their electricity bills</li>
                                    <li class="checkmark">support businesses in their efforts to stay competitive</li>
                                    <li class="checkmark">drive economic prosperity</li>
                                    <li class="checkmark">defer building new power plants and transmission lines</li>
                                    <li class="checkmark">lower electricity system costs</li>
                                </ul>
                            </div>
                            <div class="block text-right pt-2">
                                <b-button v-on:click="gotoNext" variant="primary" class="mb-1 p-hide">Next&nbsp;&#9662;</b-button>
                            </div>
                        </b-card>
                    </transition>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
<script>
import BlockMixin from './BlockMixin.vue';
export default {
    title: 'Everyone benefits from energy efficiency',
    id:'everyone-benefits-from-energy-efficiency',
    name: 'Block2',
    mixins: [ BlockMixin ],
    methods: {
        animateItem(){
            this.$el.classList.add( 'active' );
            this.show = true;
            this.animateItemNext();
        },
        animateItemNext(){
            if( this.count >= 3 ){
                return;
            }
            var $this = this;
            setTimeout( () => {
                $this.count++;
                $this.animateItemNext();
            }, $this.timer );
        }
    }
}
</script>