<template>
    <div class="wrapper block8">
        <b-container>
            <b-row>
                <b-col lg="5" class="mb-3">
                    <b-card class="text-left p-2 font-weight-bold dshadow" style="z-index:40;">
                        <b-card-title title-tag="h2" class="banner">Small Business Lighting program</b-card-title>
                        <b-card-text class="h5 pt-3 pb-3 banner-left dark-teal"><strong>2019-2020 RESULTS:</strong></b-card-text>
                        <div class="block block-slides">
                            <div class="block block-slide-fade pb-2">
                                <b-row no-gutters>
                                    <b-col cols="3">
                                        <b-img src="img/Icon_Small-Commercial_2c_rgb-01.svg" fluid-grow alt=""></b-img>
                                    </b-col>
                                    <b-col cols="9">
                                        <div class="block pl-3">
                                            <p class="block-p"><span class="h1 d-block bold mb-0">6,826</span>Businesses Supported</p>
                                        </div>
                                    </b-col>
                                </b-row>
                            </div>
                            <div class="block block-slide-fade pb-1">
                                <b-row no-gutters>
                                    <b-col cols="3">
                                        <b-img src="img/Icon_Results-Time-Up_2c_rgb-01.svg" fluid-grow alt=""></b-img>
                                    </b-col>
                                    <b-col cols="9">
                                        <div class="block pl-3">
                                            <p class="block-p"><span class="h1 d-block bold mb-0">47.2 GWh</span>Total Electricity Savings</p>
                                        </div>
                                    </b-col>
                                </b-row>
                            </div>
                            <div class="block block-slide-fade pb-3">
                                <b-row no-gutters>
                                    <b-col cols="3">
                                        <b-img src="img/Icon_Graph-Up_2c_rgb-01.svg" fluid-grow alt=""></b-img>
                                    </b-col>
                                    <b-col cols="9">
                                        <div class="block pl-3">
                                            <p class="block-p"><span class="h1 d-block bold mb-0">11.6 MW</span>Total Demand Savings</p>
                                        </div>
                                    </b-col>
                                </b-row>
                            </div>
                        </div>
                        <div class="block text-right pt-3">
                            <b-button v-on:click="gotoNext" variant="primary" class="mb-1 p-hide">Next&nbsp;&#9662;</b-button>
                        </div>
                    </b-card>
                </b-col>
                <b-col lg="7" class="pl-lg-0">
                    <transition name="slide-right">
                        <div v-if="show"  class="block">
                            <div class="block text-left mt-lg-4 p-2">
                                <p class="bold white">Providing small businesses with up to $2,000 to increase visibility and safety and help reduce energy costs</p>
                            </div>
                            <div class="block text-right nl-mrgn">
                                <b-img src="img/slide7.jpg" fluid-grow alt="" class="mb-4"></b-img>
                            </div>
                        </div>
                    </transition>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
<script>
import BlockMixin from './BlockMixin.vue';
export default {
    title: 'Small Business Lighting program',
    id: 'small-business-lighting-program',
    name: 'Block8',
    mixins: [ BlockMixin ],
    methods: {
        animateItem(){
            this.$el.classList.add( 'active' );
            this.show = true;
            this.blockSlideFade();
        }
    }
}
</script>