<template>
    <div class="wrapper block-full-content block11">
        <b-container>
            <b-card class="text-left p-2 dshadow">
                <b-card-title title-tag="h2" class="banner" style="z-index:40;">Case Study:<span class="emphasized">Peel District School Board</span></b-card-title>
                <div class="block block-banner p-1 pt-3 pt-lg-0">
                    <b-row>
                        <b-col md="5">
                            <b-img src="img/casestudy-peeldistrictschoolboard.jpg" fluid-grow alt=""></b-img>
                            <div class="block pt-3">
                                <p class="font-weight-medium">&ldquo;The IESO’s energy incentive programs have helped PDSB reduce our energy costs and our carbon footprint, as well as make progress on our environmental and social equity goals.&rdquo;</p>
                                <p class="small font-weight-semibold"><strong>SHAHID NAEEM P.ENG. PMP</strong><br>
                                Manager, Energy &amp; Sustainability<br>
                                Peel District School Board</p>
                            </div>
                        </b-col>
                        <b-col md="7">
                            <div class="block">
                                <p class="h5 block-p font-weight-semibold">The Energy and Sustainability department has been utilizing the Save on Energy incentive programs to help fund energy efficiency projects for several years, including the Energy Performance Program.</p>
                                <div class="block block-slides pt-4 pb-2">
                                    <b-row>
                                        <b-col lg="6">
                                            <div class="block block-slide-fade pb-1">
                                                <b-row no-gutters>
                                                    <b-col cols="3">
                                                        <b-img src="img/Icon_Participants_2c_rgb-01.svg" fluid-grow alt=""></b-img>
                                                    </b-col>
                                                    <b-col cols="9">
                                                        <div class="block pl-3">
                                                            <p class="block-p font-weight-semibold"><span class="h2 d-block bold mb-0">155,000</span>Students in kindergarten to grade 12</p>
                                                        </div>
                                                    </b-col>
                                                </b-row>
                                            </div>
                                            <div class="block block-slide-fade pb-1">
                                                <b-row no-gutters>
                                                    <b-col cols="3">
                                                        <b-img src="img/Icon_Small-Community_2c_rgb-01.svg" fluid-grow alt=""></b-img>
                                                    </b-col>
                                                    <b-col cols="9">
                                                        <div class="block pl-3">
                                                            <p class="block-p font-weight-semibold"><span class="h2 d-block bold mb-0">259</span>Schools operate in the municipalities of Brampton, Caledon and Mississauga</p>
                                                        </div>
                                                    </b-col>
                                                </b-row>
                                            </div>
                                            <div class="block block-slide-fade pb-1">
                                                <b-row no-gutters>
                                                    <b-col cols="3">
                                                        <b-img src="img/Icon_Urban-Community_2c_rgb-01.svg" fluid-grow alt=""></b-img>
                                                    </b-col>
                                                    <b-col cols="9">
                                                        <div class="block pl-3">
                                                            <p class="block-p font-weight-semibold"><span class="h2 d-block bold mb-0">41</span>Participating sites/buildings</p>
                                                        </div>
                                                    </b-col>
                                                </b-row>
                                            </div>
                                        </b-col>
                                        <b-col lg="6">
                                            <div class="block block-slide-fade pb-1">
                                                <b-row no-gutters>
                                                    <b-col cols="3">
                                                        <b-img src="img/Icon_Results-Time-Up_2c_rgb-01.svg" fluid-grow alt=""></b-img>
                                                    </b-col>
                                                    <b-col cols="9">
                                                        <div class="block pl-3">
                                                            <p class="block-p font-weight-semibold"><span class="h2 d-block bold mb-0">12 GWh</span>Total electricity savings over three years</p>
                                                        </div>
                                                    </b-col>
                                                </b-row>
                                            </div>
                                            <div class="block block-slide-fade pb-1">
                                                <b-row no-gutters>
                                                    <b-col cols="3">
                                                        <b-img src="img/Icon_Lightbulb_2c_rgb-01.svg" fluid-grow alt=""></b-img>
                                                    </b-col>
                                                    <b-col cols="9">
                                                        <div class="block pl-3">
                                                            <p class="block-p font-weight-semibold">Work included the installation of LED lighting, lighting controls and building automation system upgrades, among other things</p>
                                                        </div>
                                                    </b-col>
                                                </b-row>
                                            </div>
                                        </b-col>
                                    </b-row>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                    <div class="block text-right pt-3">
                        <b-button v-on:click="gotoNext" variant="primary" class="mb-1 p-hide">Next&nbsp;&#9662;</b-button>
                    </div>
                </div>
            </b-card>
        </b-container>
    </div>
</template>
<script>
import BlockMixin from './BlockMixin.vue';
export default {
    title:'Case Study: Peel District School Board',
    id: 'case-study-peel-district-school-board',
    name: 'Block11',
    mixins: [ BlockMixin ],
    methods: {
        animateItem(){
            this.$el.classList.add( 'active' );
            this.show = true;
            this.blockSlideFade();
        }
    }
}
</script>