<template>
    <div class="wrapper block15">
        <b-container>
            <b-row>
                <b-col lg="6" class="pl-lg-0">
                    <transition name="slide-right">
                        <div v-show="show" class="p-show block text-left">
                            <b-img src="img/slide12.jpg" fluid-grow alt="" class="mt-3"></b-img>
                        </div>
                    </transition>
                </b-col>
                <b-col lg="6">
                    <b-card class="text-left p-2 font-weight-bold dshadow banner-card-left" style="z-index:40;">
                        <b-card-title title-tag="h2" class="banner">Support for Indigenous communities</b-card-title>
                        <p class="pt-3 banner-left">Focused support for Indigenous communities through the First Nations Conservation Program and the Remote First Nation Energy Efficiency Pilot Program helps make electricity more affordable and improve health, safety and comfort</p>
                        <div class="block block-slides banner-left">
                            <div class="block block-slide-fade pb-1">
                                <p>The Remote Pilot Program served four communities in 2019-2020:</p>
                                <ul class="list pb-2">
                                    <li>Kasabonika Lake First Nation</li>
                                    <li>Wunnumin Lake First Nation</li>
                                    <li>North Caribou Lake First Nation</li>
                                    <li>Sachigo Lake First Nation</li>
                                </ul>
                                <p>12 more communities will be eligible in 2021</p>
                            </div>
                        </div>
                        <div class="block text-right pt-3">
                            <b-button v-on:click="gotoNext" variant="primary" class="mb-1 p-hide">Next&nbsp;&#9662;</b-button>
                        </div>
                    </b-card>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
<script>
import BlockMixin from './BlockMixin.vue';
export default {
    title: 'Support for Indigenous communities',
    id: 'support-for-indigenous-communities',
    name: 'Block14',
    mixins: [ BlockMixin ],
    methods: {
        animateItem(){
            this.$el.classList.add( 'active' );
            this.show = true;
            this.blockSlideFade();
        }
    }
}
</script>