<template>
    <div class="wrapper block14">
        <b-container>
            <b-row>
                <b-col lg="5" class="order-1 order-lg-0">
                    <b-card class="text-left p-2 font-weight-bold dshadow" style="z-index:40;">
                        <b-card-title title-tag="h2" class="banner">First Nations Conservation Program</b-card-title>
                        <p class="pt-3 banner-left">Save on Energy offers First Nation communities specialized programs that reduce costs, improve home comfort and reduce reliance on fossil fuels</p>
                        <b-card-text class="h5 banner-left pt-3 pb-1 dark-teal"><strong>2019-2020 RESULTS:</strong></b-card-text>
                        <div class="block block-slides">
                            <div class="block block-slide-fade pt-3 pb-1">
                                <b-row no-gutters>
                                    <b-col cols="3">
                                        <b-img src="img/Icon_Home_2c_rgb.svg" fluid-grow alt=""></b-img>
                                    </b-col>
                                    <b-col cols="9">
                                        <div class="block pl-3">
                                            <p class="block-p"><span class="h1 d-block bold mb-0">823</span>Homes Supported</p>
                                        </div>
                                    </b-col>
                                </b-row>
                            </div>
                            <div class="block block-slide-fade pb-1">
                                <b-row no-gutters>
                                    <b-col cols="3">
                                        <b-img src="img/Icon_Results-Time-Up_2c_rgb-01.svg" fluid-grow alt=""></b-img>
                                    </b-col>
                                    <b-col cols="9">
                                        <div class="block pl-3">
                                            <p class="block-p"><span class="h1 d-block bold mb-0">500 MWh</span>Total Electricity Savings</p>
                                        </div>
                                    </b-col>
                                </b-row>
                            </div>
                        </div>
                        <div class="block text-right pt-3">
                            <b-button v-on:click="gotoNext" variant="primary" class="mb-1 p-hide">Next&nbsp;&#9662;</b-button>
                        </div>
                    </b-card>
                </b-col>
                <b-col lg="7" class="pl-lg-0">
                    <transition name="slide-right">
                        <div v-show="show" class="p-show block text-left nl-mrgn">
                            <b-img src="img/slide11.jpg" fluid-grow alt="" class="mt-3"></b-img>
                        </div>
                    </transition>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
<script>
import BlockMixin from './BlockMixin.vue';
export default {
    title: 'First Nations Conservation Program',
    id: 'first-nations-conservation-program',
    name: 'Block15',
    mixins: [ BlockMixin ],
    methods: {
        animateItem(){
            this.$el.classList.add( 'active' );
            this.show = true;
            this.blockSlideFade();
        }
    }
}
</script>