<template>
    <div class="wrapper block17">
        <b-container>
            <b-row no-gutters>
                <b-col lg="5">
                    <b-card class="text-left p-2 font-weight-bold dshadow" style="z-index:40;">
                        <b-card-title title-tag="h2" class="banner">Why energy efficiency matters</b-card-title>
                        <div class="banner-left">
                            <p class="pt-3">At less than two cents per kilowatt-hour, it’s Ontario’s most cost-effective and enduring energy resource.</p>
                            <p>Consumers managing energy more efficiently will help address new supply needs with the potential for greater contributions as the IESO develops new approaches to energy efficiency.</p>
                        </div>
                        <div class="block text-right pt-3">
                            <b-button v-on:click="gotoNext" variant="primary" class="mb-1 p-hide">Next&nbsp;&#9662;</b-button>
                        </div>
                    </b-card>
                </b-col>
                <b-col lg="7" class="pl-lg-0">
                    <div class="block text-left mt-lg-5">
                        <div class="block block-graph">
                            <div class="block block-slides">
                                <div class="block block-slide-fade">
                                    <b-img src="img/graph_EnergEfficiencyResourceAdequacy-01.png" fluid-grow alt=""></b-img>
                                </div>
                                <div class="block block-graph-info">
                                    <b-row>
                                        <b-col md="6">
                                            <div class="block block-slide-fade">
                                                <b-row no-gutters>
                                                    <b-col cols="2">
                                                        <p class="block-p"><span class="block-graph-icon dash blue">&#8211;</span></p>
                                                    </b-col>
                                                    <b-col cols="10">
                                                        <p class="block-p">Future supply needs</p>
                                                    </b-col>
                                                </b-row>
                                            </div>
                                        </b-col>
                                        <b-col md="6">
                                            <div class="block block-slide-fade">
                                                <b-row no-gutters>
                                                    <b-col cols="2">
                                                        <p class="block-p"><span class="block-graph-icon square green">&#9608;</span></p>
                                                    </b-col>
                                                    <b-col cols="10">
                                                        <p class="block-p">Existing energy efficiency and demand response programs</p>
                                                    </b-col>
                                                </b-row>
                                            </div>
                                        </b-col>
                                    </b-row>
                                </div>
                            </div>
                            <p class="small">Energy savings represented by the green shading is inclusive of the cumulative impacts from existing Save on Energy programs (including the current framework), Codes and Standards, Industrial Conservation Initiative (ICI), and other demand response programs.</p>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
<script>
import BlockMixin from './BlockMixin.vue';
export default {
    title: 'Why energy efficiency matters',
    id: 'why-energy-efficiency-matters',
    name: 'Block17',
    mixins: [ BlockMixin ],
    methods: {
        animateItem(){
            this.$el.classList.add( 'active' );
            this.show = true;
            this.blockSlideFade();
        }
    }
}
</script>