<template>
    <div class="wrapper block-full-content block6">
        <b-container>
            <b-card class="text-left p-2 dshadow">
                <b-card-title title-tag="h2" class="banner" style="z-index:40;">Case Study:<span class="emphasized">Dream Office</span></b-card-title>
                <div class="block block-banner p-1 pt-3 pt-lg-0">
                    <b-row>
                        <b-col md="5">
                            <b-img src="img/casestudy-dream.jpg" fluid-grow alt=""></b-img>
                            <div class="block pt-3">
                                <p class="font-weight-medium">&ldquo;The IESO has been an important partner for us to achieve our sustainability goals. Leveraging the Save on Energy program enabled a pathway to smarter, more efficient and higher performing buildings.&rdquo;</p>
                                <p class="small font-weight-semibold"><strong>LEE HODGKINSON</strong><br>
                                VP, Technical Services, Sustainability &amp; ESG<br>
                                Dream Office</p>
                            </div>
                        </b-col>
                        <b-col md="7">
                            <div class="block">
                                <p class="h5 block-p font-weight-semibold">Dream retrofitted the lighting at 11 office buildings in downtown Toronto, replacing inefficient incandescent and fluorescent bulbs and magnetic ballasts with new energy-efficient and long-lasting LED lighting.</p>
                                <div class="block block-slides pt-4 pb-2">
                                    <b-row>
                                        <b-col lg="6">
                                            <div class="block block-slide-fade pb-1">
                                                <b-row no-gutters>
                                                    <b-col cols="3">
                                                        <b-img src="img/Icon_Canada_2c_rgb-01.svg" fluid-grow alt=""></b-img>
                                                    </b-col>
                                                    <b-col cols="9">
                                                        <div class="block pl-3">
                                                            <p class="block-p font-weight-semibold">Dream is one of Canada’s leading real estate companies, focused on impact investing and sustainable buildings</p>
                                                        </div>
                                                    </b-col>
                                                </b-row>
                                            </div>
                                            <div class="block block-slide-fade pb-1">
                                                <b-row no-gutters>
                                                    <b-col cols="3">
                                                        <b-img src="img/Icon_High-Rise-Towers_2c_rgb-01.svg" fluid-grow alt=""></b-img>
                                                    </b-col>
                                                    <b-col cols="9">
                                                        <div class="block pl-3">
                                                            <p class="block-p font-weight-semibold"><span class="h2 d-block bold mb-0">2M+</span>Square feet of existing office space retrofitted in a major lighting program</p>
                                                        </div>
                                                    </b-col>
                                                </b-row>
                                            </div>
                                            <div class="block block-slide-fade pb-1">
                                                <b-row no-gutters>
                                                    <b-col cols="3">
                                                        <b-img src="img/Icon_Thinking_2c_rgb-01.svg" fluid-grow alt=""></b-img>
                                                    </b-col>
                                                    <b-col cols="9">
                                                        <div class="block pl-3">
                                                            <p class="block-p font-weight-semibold">Through direct IESO funding, Dream also hired a full-time Energy Manager to identify energy-saving capital projects and make operational improvements</p>
                                                        </div>
                                                    </b-col>
                                                </b-row>
                                            </div>
                                        </b-col>
                                        <b-col lg="6">
                                            <div class="block block-slide-fade pb-1">
                                                <b-row no-gutters>
                                                    <b-col cols="3">
                                                        <b-img src="img/Icon_Lightbulb_2c_rgb-01.svg" fluid-grow alt=""></b-img>
                                                    </b-col>
                                                    <b-col cols="9">
                                                        <div class="block pl-3">
                                                            <p class="block-p font-weight-semibold">Through the incentives received from Save on Energy, Dream was able to justify a larger investment and retrofit scope, including a smart lighting control system that can be controlled using a mobile device and provides human-centric lighting</p>
                                                        </div>
                                                    </b-col>
                                                </b-row>
                                            </div>
                                            <div class="block block-slide-fade pb-1">
                                                <b-row no-gutters>
                                                    <b-col cols="3">
                                                        <b-img src="img/Icon_Results-Time-Up_2c_rgb-01.svg" fluid-grow alt=""></b-img>
                                                    </b-col>
                                                    <b-col cols="9">
                                                        <div class="block pl-3">
                                                            <p class="block-p font-weight-semibold"><span class="h2 d-block bold mb-0">4,947 MWh</span>Total Electricity Savings</p>
                                                        </div>
                                                    </b-col>
                                                </b-row>
                                            </div>
                                            <div class="block block-slide-fade pb-1">
                                                <b-row no-gutters>
                                                    <b-col cols="3">
                                                        <b-img src="img/Icon_Graph-Up_2c_rgb-01.svg" fluid-grow alt=""></b-img>
                                                    </b-col>
                                                    <b-col cols="9">
                                                        <div class="block pl-3">
                                                            <p class="block-p font-weight-semibold"><span class="h2 d-block bold mb-0">945 kW</span>Total Demand Savings</p>
                                                        </div>
                                                    </b-col>
                                                </b-row>
                                            </div>
                                        </b-col>
                                    </b-row>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                    <div class="block text-right pt-3">
                        <b-button v-on:click="gotoNext" variant="primary" class="mb-1 p-hide">Next&nbsp;&#9662;</b-button>
                    </div>
                </div>
            </b-card>
        </b-container>
    </div>
</template>
<script>
import BlockMixin from './BlockMixin.vue';
export default {
    title:'Case Study: Dream Office',
    id: 'case-study-dream-office',
    name: 'Block6',
    mixins: [ BlockMixin ],
    methods: {
        animateItem(){
            this.$el.classList.add( 'active' );
            this.show = true;
            this.blockSlideFade();
        }
    }
}
</script>