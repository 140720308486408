<template>
    <div class="wrapper block-full-content block7">
        <b-container>
            <b-card class="text-left p-2 dshadow">
                <b-card-title title-tag="h2" class="banner" style="z-index:40;">Case Study:<span class="emphasized">Great Northern Hydroponics</span></b-card-title>
                <div class="block block-banner p-1 pt-3 pt-lg-0">
                    <b-row>
                        <b-col md="5">
                            <b-img src="img/casestudy-greatnorthernhydroponics.jpg" fluid-grow alt=""></b-img>
                            <div class="block pt-3">
                                <p class="font-weight-medium">&ldquo;We were the first tomato grower to light a crop commercially in this area. If it’s a clear sky, the lighting requirement goes down to 50 per&nbsp;cent.&rdquo;</p>
                                <p class="small font-weight-semibold"><strong>GUIDO VAN HET HOF</strong><br>
                                President and General Manager<br>
                                Great Northern Hydroponics</p>
                            </div>
                        </b-col>
                        <b-col md="7">
                            <div class="block">
                                <p class="h5 block-p font-weight-semibold">This project is part of an energy-efficiency strategy that also includes measures such as energy curtains and an automation system to control light and heat, which adjusts according to available sunlight.</p>
                                <div class="block block-slides pt-4 pb-2">
                                    <b-row>
                                        <b-col lg="6">
                                            <div class="block block-slide-fade pb-1">
                                                <b-row no-gutters>
                                                    <b-col cols="3">
                                                        <b-img src="img/Icon_Canada_2c_rgb-01.svg" fluid-grow alt=""></b-img>
                                                    </b-col>
                                                    <b-col cols="9">
                                                        <div class="block pl-3">
                                                            <p class="block-p font-weight-semibold"><span class="h2 d-block bold mb-0">70-acre</span>Facility located in Kingsville, Ontario</p>
                                                        </div>
                                                    </b-col>
                                                </b-row>
                                            </div>
                                            <div class="block block-slide-fade pb-1">
                                                <b-row no-gutters>
                                                    <b-col cols="3">
                                                        <b-img src="img/Icon_Greenhouse_2c_rgb-01.svg" fluid-grow alt=""></b-img>
                                                    </b-col>
                                                    <b-col cols="9">
                                                        <div class="block pl-3">
                                                            <p class="block-p font-weight-semibold"><span class="h2 d-block bold mb-0">10</span>Varieties of tomatoes</p>
                                                        </div>
                                                    </b-col>
                                                </b-row>
                                            </div>
                                        </b-col>
                                        <b-col lg="6">
                                            <div class="block block-slide-fade pb-1">
                                                <b-row no-gutters>
                                                    <b-col cols="3">
                                                        <b-img src="img/Icon_Graph-Up_2c_rgb-01.svg" fluid-grow alt=""></b-img>
                                                    </b-col>
                                                    <b-col cols="9">
                                                        <div class="block pl-3">
                                                            <p class="block-p font-weight-semibold"><span class="h2 d-block bold mb-0">$150,000</span>Expected annual savings in energy costs by replacing half their high pressure sodium (HPS) lights with high-performance LED fixtures</p>
                                                        </div>
                                                    </b-col>
                                                </b-row>
                                            </div>
                                        </b-col>
                                    </b-row>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                    <div class="block text-right pt-3">
                        <b-button v-on:click="gotoNext" variant="primary" class="mb-1 p-hide">Next&nbsp;&#9662;</b-button>
                    </div>
                </div>
            </b-card>
        </b-container>
    </div>
</template>
<script>
import BlockMixin from './BlockMixin.vue';
export default {
    title:'Case Study: Great Northern Hydroponics',
    id: 'case-study-great-northern-hydroponics',
    name: 'Block7',
    mixins: [ BlockMixin ],
    methods: {
        animateItem(){
            this.$el.classList.add( 'active' );
            this.show = true;
            this.blockSlideFade();
        }
    }
}
</script>