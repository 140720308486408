<template>
    <div class="wrapper block10">
        <b-container>
            <div class="block">
                <div class="block">
                    <h2 class="bold white pb-4">Other specialized support for large energy consumers</h2>
                </div>
                <b-card-group deck>
                    <b-card
                        class="text-left font-weight-bold dshadow callout"
                        img-src="img/slide9a.jpg"
                        img-alt=""
                        img-top
                    >
                        <b-card-text class="pb-2"><strong>Energy Performance Program:</strong><br>performance-based incentives to drive change and deliver results</b-card-text>
                    </b-card>
                    <b-card
                        class="text-left font-weight-bold dshadow callout"
                        img-src="img/slide9b.jpg"
                        img-alt=""
                        img-top
                    >
                        <b-card-text class="pb-2"><strong>Process and Systems Upgrade:</strong><br>support for complex industrial projects</b-card-text>
                    </b-card>
                </b-card-group>
                <div class="block text-center pt-5">
                    <b-button v-on:click="gotoNext" variant="primary" class="btn-lrg mb-1 p-hide">Next&nbsp;&#9662;</b-button>
                </div>
            </div>
        </b-container>
    </div>
</template>
<script>
import BlockMixin from './BlockMixin.vue';
export default {
    title:'Other specialized support for large energy consumers',
    id: 'specialized-support',
    name: 'Block10',
    mixins: [ BlockMixin ]
}
</script>