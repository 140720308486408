<template>
    <div class="wrapper block0 p-0">
        <div class="block0-background slide-up" id="block0-slideup" :style="{ backgroundImage: `url( '${bgImg}' )` }">
            <b-container>
                <div class="block0-content">
                    <transition name="slide-fade">
                        <div v-show="show" class="p-show">
                            <h1 class="pb-4">Making a Difference with Energy Efficiency</h1>
                        </div>
                    </transition>
                    <transition name="slide-fade">
                        <div v-show="count >= 1" class="p-show">
                            <p class="h3"><strong>Learn how Save on Energy programs help businesses and residents across Ontario manage energy costs.</strong></p>
                        </div>
                    </transition>
                    <transition name="slide-fade">
                        <div v-show="count >= 2">
                            <b-button v-on:click="gotoNext" variant="primary" id="block0-start" class="mt-4 start sssshake p-hide">Start&nbsp;&#9662;</b-button>
                        </div>
                    </transition>
                </div>
            </b-container>
        </div>
    </div>
</template>
<script>
import BlockMixin from './BlockMixin.vue';
export default {
    title: 'Making a Difference',
    id: 'making-a-difference',
    name: 'Block0',
    mixins: [ BlockMixin ],
    data(){
        return {
            bgImg:'img/IESO_HamiltonSkyline-092Final.jpg'
        };
    },
    methods: {
        animateItem(){
            if( !this.show ){
                const block0Slideup = document.getElementById( 'block0-slideup' );
                block0Slideup.classList.add( 'animate' );
                this.animateItemNext();
            }
            this.show = true;
        },
        animateItemNext(){
            if( this.count >= 4 ){
                /// do the jingle
                const block0Start = document.getElementById( 'block0-start' );
                block0Start.classList.add( 'animate' );
                return;
            }
            var $this = this;
            setTimeout( () => {
                $this.count++;
                $this.animateItemNext();
            }, $this.timer );
        }
    }
}
</script>